import Toolbar from "@mui/material/Toolbar";
import {Gavel} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import {Button, Divider} from "@mui/material";
import Box from "@mui/material/Box";
import i18next from "i18next";
import Link from "@mui/material/Link";

export default function Navbar() {

    function setQueryParams(lang) {
        i18next.changeLanguage(lang)
    };

    const handleClick = (event) => {
        setQueryParams(event.target.value)
    };

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" color="inherit">
                <Toolbar>
                    <Gavel></Gavel>
                    <Typography
                        // component="div"
                        // sx={{  }}
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            fontWeight: 200,
                            fontFamily: 'roboto',
                            color: 'inherit',
                            letterSpacing: '.2rem',
                            textDecoration: 'none',
                            flexGrow: 1
                        }}
                    >
                        EERHERSTEL
                    </Typography>
                    {/*<Button href="/help-find-prosecutor-office">Find Prosecutor's Office</Button>*/}
                    <Button color="inherit" value='fr' onClick={handleClick}>FR</Button>
                    <Button color="inherit" value='en' onClick={handleClick}>EN</Button>
                    <Button color="inherit" value='nl' onClick={handleClick}>NL</Button>
                </Toolbar>
            </AppBar>

        </Box>
    );
}
