import './App.css';
import {Route, Routes} from 'react-router-dom';
import MainPage from "./components/skeleton/MainPage";
import CheckoutResult from "./components/CheckoutResult";
import React from "react";
import Navbar from "./components/skeleton/Navbar";
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Footer from './components/skeleton/Footer';
import HelpFindProsOffice from "./components/HelpFindProsecutorOffice";

const defaultTheme = createTheme();

function App(props) {
    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <Container maxWidth="lg" >
                    <Navbar title="EERHERSTEL" props/>
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/checkout/result" element={<CheckoutResult/>}/>
                        <Route path="/help-find-prosecutor-office" element={<HelpFindProsOffice/>}/>
                    </Routes>
                    <Footer
                        title="For more information"
                        description="Please visit my website!"
                    />
                </Container>
            </ThemeProvider>
        </>
    );
}

export default App;
