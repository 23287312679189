import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionList from "./AccordionList";
import {Container} from "@mui/material";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const accordions = [
    {
        id: 'panel1',
        accordionSummary: '#1 De ene straf is de andere niet',
        accordionDetails: 'Hoe je strafblad blanco maken? Dat hangt af van de grootte van de straf.  Wie de straf heeft uitgesproken, is minder belangrijk. De politierechtbank kan een correctionele straf opleggen. En de correctionele rechtbank kan ook een politiestraf opleggen. Het enige dat telt, is de hoogte van de straf.',
        listSubheader: 'Soorten straffen',
        items: [
            {
                listItemText: 'Politiestraf',
                description:
                    'Gevangenisstraf van 1 tot 7 dagen, een werkstraf van 20 tot 45 uur of een geldboete van 1 tot 25 euro. Let op: het bedrag van de boete moet je nog vermenigvuldigen met de ‘opdeciemen’. Momenteel moet je een boete maal 8 doen om het uiteindelijke bedrag te kennen. Een politiestraf is dus een geldboete van 8 tot 200 EUR. De gerechtskosten en andere bijdragen tellen niet mee.'
            },
            {
                listItemText: 'Correctionele straf',
                description:
                    'Gevangenisstraf van 8 dagen tot 5 jaar, een werkstraf van 45 tot 200 uur of een boete van meer dan 25 euro, lees: een boete van meer dan 200 EUR.'
            },
            {
                listItemText: 'Criminele straf',
                description:
                    'Opsluiting of hechtenis (deze laatste straf is voorbehouden voor politieke misdaden)'
            },
        ],
    },{
        id: 'panel2',
        accordionSummary: '#2 Uitwissing, eerherstel, herstel in eer en rechten',
        accordionDetails: 'Nu je weet wat voor straf er werd opgelegd, weet je ook welke procedure je moet volgen om je strafregister weer blanco te maken.',
        listSubheader: '',
        items: [
            {
                listItemText: 'Uitwissing van politiestraffen',
                description:
                    'Een politiestraf wordt automatisch uitgewist, drie jaar na de dag van de uitspraak. Je moet daar dus niks voor doen. Een uitwissing betekent niet dat je de boete niet meer moet betalen!',
            },
            {
                listItemText: 'Eerherstel aanvragen voor alle andere straffen',
                description:
                    'Andere veroordelingen verdwijnen niet automatisch van het strafregister. Dus daarvoor moet je een eerherstel of herstel in eer en rechten aanvragen.',

            },
        ],
    }, {
        id: 'panel3',
        accordionSummary: '#3 Voorwaarden eerherstel aanvragen',
        accordionDetails: 'Wil je eerherstel aanvragen, dan moet je aan een aantal voorwaarden voldoen.',
        listSubheader: '',
        items: [
            {
                listItemText: 'Je kan maar één keer per 10 jaar eerherstel aanvragen.',
                description: '',
            },
            {
                listItemText: 'Tenzij je daarvan uitdrukkelijk bent vrijgesteld, moet je ook',
                description:
                    'gevangenisstraf hebben uitgezete, boetes hebben betaald, kosten hebben betaald, slachtoffers hebben vergoed, eventuele zaken hebben teruggegeven '
            },
            {
                listItemText: 'Als de straffen zijn verjaard, mag dat ook niet aan jou liggen',
                description: ''
            },
        ],
    },{
        id: 'panel4',
        accordionSummary: '#4 Proeftijd?',
        accordionDetails: 'Bovendien kan je pas eerherstel aanvragen na een bepaalde proeftijd.' +
            'Tijdens die proeftijd moet je: ' +
            'Een vaste verblijfplaats hebben, in België of in het buitenland. Vanzelfsprekend moet je niet al die tijd op hetzelfde adres hebben gewoond. Je moet wel altijd een officieel adres hebben gehad' +
            'and blijk geven van verbetering en van goed gedrag. Dat betekent dat je tijdens je proeftijd geen nieuwe feiten pleegt.' +
            'Maar let op! De proeftijd begint pas te lopen als je de volledige boete hebt betaald. Bovendien kan je ten vroegste 1 jaar vóór het verstrijken van de proeftermijn eerherstel aanvragen. Als de straffen met uitstel werden uitgesproken, is de proeftijd minstens even lang als de duur van het uitstel.\n',
        listSubheader: 'De duur van de proeftijd hangt ook af van het soort straf.',
        items: [
            {
                listItemText: 'Voor gevangenisstraffen tot 5 jaar bedraagt de proeftijd 3 jaar.',
                description:'',
            },
            {
                listItemText: 'Voor gevangenisstraffen vanaf 5 jaar bedraagt de proeftijd 5 jaar',
                description:'',
            },
            {
                listItemText: 'Bij herhaling of terbeschikkingstelling bedraagt de proeftijd minstens 6 jaar',
                description:'',
            },
            {
                listItemText: 'Wanneer een straf van meer dan 5 jaar wegens herhaling werd uitgesproken, bedraagt de proeftijd 10 jaar.',
                description:'',
            },
        ],
    },{
        id: 'panel5',
        accordionSummary: '#5 Eerherstel aanvragen',
        accordionDetails: 'Als je aan de voorwaarden voldoet, bezorg je – ten vroegste een jaar voor het verstrijken van de proeftijd – een verzoek aan de procureur des Konings. Een gewone brief volstaat. Die verstuur je aan de procureur des Konings van je woonplaats. De brief moet niet aan bepaalde voorwaarden voldoen, maar hij moet wel minstens',
        listSubheader: '',
        items: [
            {
                listItemText: 'Politiestraf',
                description:'Vermelden voor welke veroordelingen je eerherstel aanvraagt',
            },
            {
                listItemText: 'Een overzicht bevatten van de plaatsen waar je de laatste 10 jaar heeft gewoond.',
                description:'',
            },
        ],
    },{
        id: 'panel6',
        accordionSummary: '#6 Documenten',
        accordionDetails: 'Je stuurt je aanvraag tot eerherstel aan de procureur des Konings. Deze vraagt advies aan de korpschef van je woonplaats. Na een eerste controle stuurt die je aanvraag naar de Kamer van Inbeschuldigingstelling. De KI doet uiteindelijk uitspraak. In principe moet je niet aanwezig zijn op deze zitting.',
        listSubheader: '',
        items: [
            {
                listItemText: 'Historiek van adressen (verkrijgbaar bij de gemeente)',
                description:'',
            },
            {
                listItemText: 'Uittreksel uit het strafregister (verkrijgbaar bij de gemeente)',
                description:'',
            },
        ],
    },{
        id: 'panel7',
        accordionSummary: '#7 Gevolgen',
        accordionDetails: 'Hou er rekening mee dat de procedure – van aanvraag tot beslissing – tot één jaar kan duren.\n' +
            '\n' +
            'Door de uitwissing of het eerherstel komen één of meer veroordelingen niet meer voor op je strafregister.\n' +
            '\n' +
            'Een adellijke titel of een graad in het leger die je verloor door de veroordeling, krijg je er niet door terug.\n' +
            '\n' +
            'Bovendien blijf je verplicht om de slachtoffers te vergoeden. Een uitwissing of eerherstel betekent ook niet dat je de boetes niet meer moet betalen (zolang ze niet verjaard zijn).\n' +
            '\n',
        listSubheader: '',
        items: [
        ],
    },

];

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Container>
            {accordions.map((accordion) => (
                <Accordion expanded={expanded === accordion.id} onChange={handleChange(accordion.id)} >
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{accordion.accordionSummary}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {accordion.accordionDetails}
                            <AccordionList accordionList={accordion.items} subHeader={accordion.listSubheader}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
}