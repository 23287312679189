import React from 'react';
import Container from "@mui/material/Container";
import MovieClip from "./MovieClip";
import Box from "@mui/material/Box";
import {Button, Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import BasicModal from "./BasicModal";

const HelpFindProsOffice = () => {
    const {t, i18n} = useTranslation();
    return (

        <Container maxWidth="lg">

            <h1>{t('form.prosec.descr')}</h1>
            <p>  </p>
            <Button href="https://territoriale-bevoegdheid.just.fgov.be/cgi-main/competence-territoriale.pl">{t('form.prosec.act')} </Button>
            <p>  </p>
            <p>  </p>
            <p>  </p>
            <Box>
                <MovieClip/>
            </Box>
        </Container>
    );
};

export default HelpFindProsOffice;
