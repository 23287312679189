import React, {useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import {useTranslation} from "react-i18next";
import BasicModal from "../BasicModal";

const FormWithStepper = () => {
        const [activeStep, setActiveStep] = useState(0);
        const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
        const [isFirstDemandWithinTenYears, setIsFirstDemandWithinTenYears] = React.useState(true);
        const [isAdditionalInfoValid, setIsAdditionalInfoValid] = React.useState(false);

        const {t, i18n} = useTranslation();

        const [judgmentFields, setJudgmentFields] = useState([
            {
                date: '',
                court: '',
                courtCity: '',
                sentenceType: '',
                sentenceTime: '',
                sentenceFine: '',
                deferral: false,
                deferralTime: '',
                repeatOffence: false,
            },
        ]);

        const [cachedJudgmentFields, setCachedJudgmentFields] = useState(
            judgmentFields
        );

        const [additionalInfoFields, setAdditionalInfoFields] = useState({
            dateOfBirth: '',
            fullName: '',
            emailAddress: '',
            city: '',
            zipcode: '',
            address: '',
            phoneNumber: '',
            country: '',
            province: '',
        });

        const handleAddJudgment = () => {
            const newJudgment = {
                date: '',
                court: '',
                courtCity: '',
                sentenceType: '',
                sentenceTime: '',
                sentenceFine: '',
                deferral: false,
                deferralTime: '',
                repeatOffence: false,
            };
            setCachedJudgmentFields([...cachedJudgmentFields, newJudgment]);
        };

        const handleRemoveJudgment = () => {
            if (cachedJudgmentFields.length > 1) {
                const updatedCachedJudgmentFields = [...cachedJudgmentFields];
                updatedCachedJudgmentFields.pop();
                setCachedJudgmentFields(updatedCachedJudgmentFields);
            }
        };

        const handleNext = () => {
            if (activeStep === 0) {
                // Implement validation for the judgment block
                // If validation passes, proceed to the next step
                // Otherwise, display an error message or prevent the transition
                setJudgmentFields(cachedJudgmentFields);
                console.log(judgmentFields);
            } else {
                // Handle transition to the "Additional Infos" step
                // Implement any additional validation for this step
                // If all validation passes, proceed to the "Additional Infos" step
            }
            setActiveStep(activeStep + 1);
        };

        const handleBack = () => {
            setActiveStep(activeStep - 1);
        };
        const handleFirstDemandWithinTenYears = (event) => {
            setIsFirstDemandWithinTenYears(event.target.checked)
            if (!event.target.checked) {
                setIsSubmitDisabled(true);
            } else {
                setIsSubmitDisabled(false);
            }
        };

        const handleJudgmentInputChange = (field, value, index) => {
            const updatedJudgmentFields = [...cachedJudgmentFields];
            updatedJudgmentFields[index][field] = value;
            setCachedJudgmentFields(updatedJudgmentFields);

            if (field === 'date' ||
                field === 'sentenceType' ||
                field === 'sentenceTime' ||
                field === 'deferral' ||
                field === 'deferralTime' ||
                field === 'repeatOffence' ||
                field === 'isFirstDemandWithinTenYears'
            ) {
                validateJudgmentFields(index);
            }
        };

        const validateJudgmentFields = (index) => {
            let judgment = judgmentFields[index];
            const currentDate = new Date();
            const inputDate = new Date(judgment.date);
            const sentenceTime = parseInt(judgment.sentenceTime, 10);
            const deferralTime = parseInt(judgment.deferralTime, 10);
            let isElapsedMoreThen2Years = inputDate.setFullYear(inputDate.getFullYear() + 2, inputDate.getMonth()) <= currentDate;
            let isElapsedMoreThen4Years = inputDate.setFullYear(inputDate.getFullYear() + 4, inputDate.getMonth()) <= currentDate;
            let isElapsedMoreThen5Years = inputDate.setFullYear(inputDate.getFullYear() + 5, inputDate.getMonth()) <= currentDate;
            let isElapsedMoreThen9Years = inputDate.setFullYear(inputDate.getFullYear() + 9, inputDate.getMonth()) <= currentDate;
            let isElapsedMoreThenDeferralTimeMinus1Years = inputDate.setFullYear(inputDate.getFullYear() + deferralTime - 1) <= currentDate;

            const fine = parseInt(judgment.sentenceFine, 10);

            // isFirstDemandWithinTenYears must be checked to proceed
            if (!isFirstDemandWithinTenYears) {
                setIsSubmitDisabled(true);
            }

            // only fine
            if (judgment.sentenceType.valueOf() === 'fine') {
                console.log("Fine...")
                if (!isElapsedMoreThen2Years) {
                    console.log("isElapsedMoreThen2Years: Not enough time elapsed for petition.")
                    setIsSubmitDisabled(true)
                    return;
                } else {
                    console.log("Enough time elapsed for petition. Is there a deferral?")
                    if (judgment.deferral && judgment.deferralTime >= 3 && !isElapsedMoreThenDeferralTimeMinus1Years) {
                        console.log("isElapsedMoreThenDeferralTimeMinus1Years: Not enough time elapsed for petition.")
                        setIsSubmitDisabled(true)
                        return;
                    }
                }
            } else { //prison or (fine and prison)
                if (sentenceTime < 5 && judgment.repeatOffence && !isElapsedMoreThen5Years) {
                    console.log("isElapsedMoreThen5Years: Not enough time elapsed for petition.")
                    setIsSubmitDisabled(true)
                    return;
                } else if (sentenceTime < 5 && !judgment.repeatOffence) {
                    if (!isElapsedMoreThen2Years) {
                        console.log("sentenceTime < 5 && !judgment.repeatOffence && !isElapsedMoreThen2Years")
                        setIsSubmitDisabled(true)
                        return;
                    } else if (judgment.deferral && judgment.deferralTime >= 3 && !isElapsedMoreThenDeferralTimeMinus1Years) {
                        console.log("isElapsedMoreThenDeferralTimeMinus1Years: Not enough time elapsed for petition.")
                        setIsSubmitDisabled(true)
                        return;
                    }
                } else if (sentenceTime >= 5 && !judgment.repeatOffence && !isElapsedMoreThen4Years) {
                    console.log("sentenceTime > 5 && !judgment.repeatOffence && !isElapsedMoreThen4Years")
                    setIsSubmitDisabled(true)
                    return;
                } else if (sentenceTime >= 5 && judgment.repeatOffence && !isElapsedMoreThen9Years) {
                    console.log("sentenceTime > 5 && judgment.repeatOffence && !isElapsedMoreThen9Years")
                    setIsSubmitDisabled(true)
                    return;
                }
            }

            console.log("No hinder there, user can order petition pdf.")
            setIsSubmitDisabled(false);
            return;
        }


        const handleAdditionalInfosInputChange = (field, value) => {
            setAdditionalInfoFields({
                ...additionalInfoFields,
                [field]: value,
            });
            setIsAdditionalInfoValid(checkAdditionalInfoValidity)
        };

        function checkAdditionalInfoValidity() {
            return Object.values(additionalInfoFields).every(
                (value) => value !== ''
            );
        }

        const handleSubmit = () => {
            console.log(judgmentFields);
            console.log(additionalInfoFields);
            const formData = {
                judgments: judgmentFields,
                additionals: additionalInfoFields
            };
            console.log(formData);

            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formData),
                // redirect: 'follow'
            };
            let url = 'api/payments';
            fetch(url, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    // Handle the successful response here
                    console.log(response);

                    // Extract the URL from the response
                    return response.text();
                })
                .then((redirectUrl) => {
                    // Redirect to the new URL
                    window.location.href = redirectUrl;
                })
                .catch((error) => {
                    console.error('There was a problem with the fetch operation:', error);
                });

        };

        const renderJudgmentFields = () => {
            return cachedJudgmentFields.map((judgment, index) => (
                <Box key={index} border={1}
                     borderColor="grey.500"
                     borderRadius="16px"
                     m={2} p={3}>
                    <Typography variant="h6" gutterBottom sx={{pt: 3, pb: 5}}>{t('form.judgment.is')} {index + 1}</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                id={`date-${index}`}
                                required
                                label={t('form.date')}
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={judgment.date}
                                onChange={(e) => handleJudgmentInputChange('date', e.target.value, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth required>
                                <InputLabel shrink id={`court-label-${index}`}>
                                    Court
                                </InputLabel>
                                <Select
                                    labelId={`court-label-${index}`}
                                    label={t('form.court.is')}
                                    id={`court-${index}`}
                                    value={judgment.court}
                                    onChange={(e) => handleJudgmentInputChange('court', e.target.value, index)}
                                >
                                    <MenuItem value="Politierechtbank">Politierechtbank</MenuItem>
                                    <MenuItem value="Correctionele Rechtbank">Correctionele Rechtbank</MenuItem>
                                    <MenuItem value="Arbeidsrechtbank">Arbeidsrechtbank</MenuItem>
                                    <MenuItem value="Hof van Beroep">Hof van Beroep</MenuItem>
                                    <MenuItem value="Arbeidshof">Arbeidshof</MenuItem>
                                    <MenuItem value="Hof van Assisen">Hof van Assisen</MenuItem>
                                    <MenuItem value="Buitenlandse jurisdictie">Buitenlandse jurisdictie</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label={t('form.court.city')}
                                id={`courtCity-${index}`}
                                value={judgment.courtCity}
                                onChange={(e) => handleJudgmentInputChange('courtCity', e.target.value, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth required>
                                <InputLabel shrink id={`sentence-type-label-${index}`}>
                                    {t('form.sentence.type')}
                                </InputLabel>
                                <Select
                                    labelId={`sentence-type-label-${index}`}
                                    label="Sentence Type"
                                    id={`sentenceType-${index}`}
                                    value={judgment.sentenceType}
                                    onChange={(e) => handleJudgmentInputChange('sentenceType', e.target.value, index)}
                                >
                                    <MenuItem value="prison">Gevangenis</MenuItem>
                                    <MenuItem value="fine">Boete</MenuItem>
                                    <MenuItem value="both">Beide</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required={judgment.sentenceType === 'prison' || judgment.sentenceType === 'both'}
                                fullWidth
                                label={t('form.sentence.time')}
                                type="number"
                                id={`sentenceTime-${index}`}
                                value={judgment.sentenceTime}
                                disabled={judgment.sentenceType.valueOf() === 'fine'}
                                onChange={(e) => handleJudgmentInputChange('sentenceTime', e.target.value, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required={judgment.sentenceType === 'fine' || judgment.sentenceType === 'both'}
                                fullWidth
                                label={t('form.sentence.fine')}
                                type="number"
                                id={`sentenceFine-${index}`}
                                value={judgment.sentenceFine}
                                disabled={judgment.sentenceType.valueOf() === 'prison'}
                                onChange={(e) => handleJudgmentInputChange('sentenceFine', e.target.value, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Checkbox
                                id={`deferral-${index}`}
                                value={judgment.deferral}
                                onClick={(e) => handleJudgmentInputChange('deferral', e.target.checked, index)}
                            />{t('form.deferral.is')}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                required={judgment.deferral}
                                fullWidth
                                label={t('form.deferral.time')}
                                type="number"
                                id={`deferralTime-${index}`}
                                value={judgment.deferralTime}
                                disabled={!judgment.deferral}
                                onChange={(e) => handleJudgmentInputChange('deferralTime', e.target.value, index)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Checkbox
                                id={`repeatOffence-${index}`}
                                value={judgment.repeatOffence}
                                onClick={(e) => handleJudgmentInputChange('repeatOffence', e.target.checked, index)}
                            />
                            {t('form.repeat.offence')}
                        </Grid>
                    </Grid>
                </Box>
            ));
        };

        const renderAdditionalInfos = () => {
            return (
                <Box>
                    <Typography variant="h6" gutterBottom sx={{pt: 3, pb: 5}}>Additional Infos</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('form.dob')}
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={additionalInfoFields.dateOfBirth}
                                onChange={(e) => handleAdditionalInfosInputChange('dateOfBirth', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="fullName"
                                name="fullName"
                                label={t('form.fullName')}
                                fullWidth
                                variant="standard"
                                value={additionalInfoFields.fullName}
                                onChange={(e) => handleAdditionalInfosInputChange('fullName', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="emailAddress"
                                name="emailAddress"
                                label={t('form.email')}
                                fullWidth
                                variant="standard"
                                value={additionalInfoFields.emailAddress}
                                onChange={(e) => handleAdditionalInfosInputChange('emailAddress', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="phoneNumber"
                                name="phoneNumber"
                                label={t('form.phone')}
                                fullWidth
                                variant="standard"
                                value={additionalInfoFields.phoneNumber}
                                onChange={(e) => handleAdditionalInfosInputChange('phoneNumber', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="address"
                                name="address"
                                label={t('form.address')}
                                fullWidth
                                autoComplete="shipping address-line"
                                variant="standard"
                                value={additionalInfoFields.address}
                                onChange={(e) => handleAdditionalInfosInputChange('address', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="city"
                                name="city"
                                label={t('form.city')}
                                fullWidth
                                autoComplete="shipping address-level2"
                                variant="standard"
                                value={additionalInfoFields.city}
                                onChange={(e) => handleAdditionalInfosInputChange('city', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="zipcode"
                                name="zipcode"
                                label={t('form.zip')}
                                fullWidth
                                autoComplete="shipping postal-code"
                                variant="standard"
                                value={additionalInfoFields.zipcode}
                                onChange={(e) => handleAdditionalInfosInputChange('zipcode', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="country"
                                name="country"
                                label={t('form.country')}
                                fullWidth
                                autoComplete="shipping country"
                                variant="standard"
                                value={additionalInfoFields.country}
                                onChange={(e) => handleAdditionalInfosInputChange('country', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel shrink id="province">
                                    {t('form.province.is')}
                                </InputLabel>
                                <Select
                                    labelId={`province-label`}
                                    label={t('form.province')}
                                    id={`province`}
                                    value={additionalInfoFields.province}
                                    onChange={(e) => handleAdditionalInfosInputChange('province', e.target.value)}
                                >
                                    <MenuItem value="antwerpen">{t('form.province.antwerpen')}</MenuItem>
                                    <MenuItem value="brabant">{t('form.province.brabant')}</MenuItem>
                                    <MenuItem value="brussel">{t('form.province.brussel')}</MenuItem>
                                    <MenuItem value="charleroi">{t('form.province.charleroi')}</MenuItem>
                                    <MenuItem value="eupen">{t('form.province.eupen')}</MenuItem>
                                    <MenuItem value="halle">{t('form.province.hallee')}</MenuItem>
                                    <MenuItem value="leuven">{t('form.province.leuven')}</MenuItem>
                                    <MenuItem value="liege">{t('form.province.liege')}</MenuItem>
                                    <MenuItem value="limburg">{t('form.province.limburg')}</MenuItem>
                                    <MenuItem value="luxemburg">{t('form.province.luxemburg')}</MenuItem>
                                    <MenuItem value="mons">{t('form.province.mons')}</MenuItem>
                                    <MenuItem value="namur">{t('form.province.namur')}</MenuItem>
                                    <MenuItem value="oost">{t('form.province.oost')}</MenuItem>
                                    <MenuItem value="west">{t('form.province.west')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            );
        };

        return (
            <form>
                <Container maxWidth="md">
                    <Stepper activeStep={activeStep} alternativeLabel sx={{pt: 3, pb: 5}}>
                        <Step>
                            <StepLabel>{t('form.stepper.judgments')}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>{t('form.stepper.addinfo')}</StepLabel>
                        </Step>
                    </Stepper>
                    <React.Fragment>
                        {activeStep === 0 && renderJudgmentFields()}
                        {activeStep === 0 && (
                            <Container>
                                <Checkbox
                                    required
                                    id={`firstDemandWithinTenYears`}
                                    value={isFirstDemandWithinTenYears}
                                    onClick={handleFirstDemandWithinTenYears}
                                />
                                {t('form.first.demand')}
                            </Container>
                        )
                        }
                        {activeStep === 1 && renderAdditionalInfos()}

                        {isSubmitDisabled && (
                            <Alert severity="warning">{t('form.judgment.alert')}</Alert>
                        )}

                        {activeStep === 1 && !isAdditionalInfoValid && (
                            <Alert severity="warning">{t('form.addinfo.alert')}</Alert>
                        )}


                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>

                            {activeStep === 1 ? (
                                <Button sx={{mt: 3, ml: 1}} onClick={handleBack} variant="outlined">Back</Button>
                            ) : (
                                <>
                                    <Button
                                        onClick={handleRemoveJudgment}
                                        variant="outlined"
                                        color="secondary"
                                        disabled={cachedJudgmentFields.length <= 1}
                                        sx={{mt: 3, ml: 1}}
                                    >
                                        {t('form.button.remove')}
                                    </Button>
                                    <Button
                                        onClick={handleAddJudgment}
                                        variant="outlined"
                                        color="primary"
                                        sx={{mt: 3, ml: 1}}
                                        disabled={isSubmitDisabled}
                                    >
                                        {t('form.button.add')}
                                    </Button>
                                </>
                            )}
                            {(activeStep === 0) ? (
                                <Button onClick={handleNext} variant="outlined" color="primary" sx={{mt: 3, ml: 1}}
                                        disabled={isSubmitDisabled}>
                                    {t('form.button.next')}
                                </Button>
                            ) : (
                                <Button onClick={handleSubmit} variant="outlined" color="primary" sx={{mt: 3, ml: 1}}
                                        disabled={!isAdditionalInfoValid}>
                                    {t('form.button.order')}
                                </Button>
                            )}


                        </Box>
                        {activeStep === 0 &&
                            <Typography sx={{fontStyle: "italic", m: 1}}>
                                {t('form.note')}
                            </Typography>}
                    </React.Fragment>
                </Container>
            </form>
        );
    }
;

export default FormWithStepper;
