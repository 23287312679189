import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

function Header() {
    const {t, i18n} = useTranslation();

    return (
        <Paper
            sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(./court.jpeg)`,
            }}
        >
            {/* Increase the priority of the hero background image */}
            {<img style={{display: 'none'}} src='./court.jpeg' alt='main image text'/>}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',
                }}
            />
            <Grid container>
                {/*<Grid item md={6}>*/}
                <Stack item>
                    <Box
                        sx={{
                            position: 'relative',
                            p: {xs: 3, md: 6},
                            pr: {md: 0},
                        }}
                    >
                        <Typography component="h3" variant="h3" color="inherit" gutterBottom>
                            {t('mainpage.mainFeaturedPost.title')}
                        </Typography>
                        <Typography variant="h5" color="inherit" paragraph>
                            {t('mainpage.mainFeaturedPost.description')}
                        </Typography>
                    </Box>
                </Stack>
                {/*</Grid>*/}
            </Grid>
        </Paper>
    );
}

export default Header;
