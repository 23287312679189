import React from "react";
import YouTube from "react-youtube";

class MovieClip extends React.Component {
    render() {
        const options = {
            // height: '390',
            height: '780',
            // width: '640',
            width: '1280',
            playerVars: {
                playsinline: 1,
                autoplay: 1,
                controls: 1,
            },
        };

        return <YouTube videoId="SPIg1WRUfUM" opts={options} onReady={this._onReady} id="video"/>;
    }

    _onReady(event) {
        event.target.pauseVideo();
    }
}

export default MovieClip;