import React from 'react';
import Container from "@mui/material/Container";

const CheckoutResult = () => {
    return (

        <Container maxWidth="lg">

            <h1>Please check your emails.</h1>
            <p>You will receive a confirmation email shortly with all the details of your purchase. </p>
            <p> If you have any questions or need further assistance, please don't hesitate to contact our support
                team.</p>
        </Container>
    );
};

export default CheckoutResult;
