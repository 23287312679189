import * as React from 'react';
import Container from '@mui/material/Container';
import Header from './Header';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import FormWithStepper from "../form/FormWithStepper";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import CustomizedAccordions from "../CustomizedAccordion";

export default function MainPage() {
    const {t, i18n} = useTranslation();

    return (
        <main>
            <Header/>

            <Box m={2} pt={3} pb={3}>
                <Typography component="h2" variant="h5" color="inherit" sx={{flex: 1}}>
                    {t('mainpage.heading')}
                </Typography>
            </Box>

            <Container component="main" maxWidth="xl" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h4" align="center" style={{padding: '30px'}}>
                        Wil je nu eerherstel aanvragen?
                    </Typography>
                    <FormWithStepper/>

                </Paper>
            </Container>

            {/*<CustomizedAccordions/>*/}

        </main>
    );
}
